<template>
  <b-sidebar id="add-edit-category-sidebar" :visible="isAddEditCategorySidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-edit-category-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
        <h5 class="mb-0">Edit Topics</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Full Name -->
          <validation-provider #default="validationContext" name="Topic Name" rules="required">
            <b-form-group label="Topic Name" label-for="Topic Name">
              <b-form-input id="Edit-Category" v-model="CategoryData.category_name" autofocus
                :state="getValidationState(validationContext)" trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="Sort Category" rules="required">
            <b-form-group label="Sort Category" label-for="Sort Category">
              <b-form-input id="Sort-Category" v-model="CategoryData.order" autofocus
                :state="getValidationState(validationContext)" trim type="number" placeholder="Enter Numeric Value" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <br />

          <template v-if="getFileExtensionFromUrl(CategoryData.category_img) == 'json'">
            <LottieView :url="CategoryData.category_img" :forEditTopics="true" :key="keyForLottie" />
          </template>
          <template v-else-if="getFileExtensionFromUrl(CategoryData.category_img) == 'lottie'">
            <DoLottieView :url="CategoryData.category_img" :forEditTopics="true" 
            :id="`topic_image_${uuidv4()}`"  :key="keyForLottie" />
          </template>

          <template v-else>
            <img style="width: 200px; height: 150px" :src="CategoryData.category_img" />
          </template>
          <br />
          <br />
          <b-button variant="primary" @click="showgalleryimage">
            <!-- <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="inputImageRenderer"
            /> -->
            <span class="d-none d-sm-inline">Images gallery</span>
            <!-- <feather-icon icon="EditIcon" class="d-inline d-sm-none" /> -->
          </b-button>
          <b-button style="margin-left: 10px" variant="primary" @click="$refs.refInputEl.click()">
            <input ref="refInputEl" type="file" class="d-none" @input="inputImageRenderer" />
            <span class="d-none d-sm-inline">Upload</span>
            <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
          </b-button>

          <zape-gallery-model :props_hideShowModel="showImage" @modelClose="modelClose"
            @saveCategoryImage="saveCategoryImage" :keyForLottie="keyForLottie" />
          <!-- <br />
          <br /> -->
          <div style="height: 200px !important; display: flex; align-items: center; margin-bottom: 40px;">
            <img style="width: 150px; height: 120px" v-if="CategoryData.video == null"
              src="https://zape02.s3.ap-southeast-1.amazonaws.com/0.76871584038628660.98656671003321120.19322443436631032.png" />
            <div style="width: 150px; height:   120px; object-fit: contain;"
              v-if="CategoryData.video != null && CategoryData.video.video_uri">
              <b-embed type="video" aspect="4by3" controls :poster="CategoryData.video.videoThumbnail">
                <source :src="CategoryData.video.video_uri" type="video/webm" />
                <source :src="CategoryData.video.video_uri" type="video/x-m4v" />
                <source :src="CategoryData.video.video_uri" type="video/mp4" />
                <source :src="CategoryData.video.video_uri" type="video/avi" />
              </b-embed>
            </div>

            <b-button variant="primary" @click="showVideosModel" class="m-1">
              <span class="d-none d-sm-inline">Select</span>
            </b-button>
            <!-- <br />
            <br /> -->
          </div>

          <zape-video-model :props_hideShowModel="statusVideosModel" @modelClose="modelVideoClose"
            @saveCategoryVideo="saveCategoryVideo" />

          <div class="d-flex bg-light text-light align-items px-3 py-2"
            style="position: absolute; bottom: 0%; left: 0%; width: 100%">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" type="submit" @click="hide"
              :disabled="submitbutton">
              update
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" style="margin-left: 10px"
              variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>


<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormFile,
  BCol,
  BEmbed,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "../../../../@fake-db/data/other/countries";
import store from "@/store";
import axios from "axios";
import S3 from "aws-s3";
import ZapeGalleryModel from "../../../components/Model/ZapeGalleryModel.vue"
import ZapeVideoModel from "../../../components/Model/ZapeVideoModel.vue"
import LottieView from "@/views/components/question/LottieView.vue";
import DoLottieView from "@/views/components/question/DoLottieView.vue";
import { v4 as uuidv4 } from 'uuid';
export default {
  components: {
    ZapeVideoModel,
    ZapeGalleryModel,
    BSidebar,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BEmbed,
    LottieView,
    DoLottieView,
    uuidv4,

  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddEditCategorySidebarActive",
    event: "update:is-add-edit-category-sidebar-active",
  },
  props: {
    isAddEditCategorySidebarActive: {
      type: Boolean,
      required: true,
    },
    Editclassdata: {
      type: String,
      required: true,
    },
    Editsubjectid: {
      type: String,
      required: true,
    },
    Editcategoryid: {
      type: String,
      required: true,
    },
    Editcategoryname: {
      type: String,
      required: true,
    },
    EditCategoryImage: {
      type: String,
      required: true,
    },
    editOrder: {
      type: String,
      required: true,
    },
    EditCategoryVideo: {
      type: Object,
      required: true,
    }
  },
  watch: {
    Editcategoryname() {
      this.CategoryData.category_name = this.Editcategoryname;
    },
    editOrder() {
      this.CategoryData.order = this.editOrder;
    },
    EditCategoryImage() {
      this.CategoryData.category_img = this.EditCategoryImage;
    },
    EditCategoryVideo() {
      this.CategoryData.video = this.EditCategoryVideo;
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      searchQuery: "",
      showImage: false,
      removebutton: true,
      CategoryData: {
        category_name: "",
        category_img: "",
        order: 1,
        video: {}
      },
      table_data: {},
      orginal_name: "",
      edit_category_data: "",
      statusVideosModel: false,
      keyForLottie: 0
    };
  },
  created() {
    // this.gettabledata();
  },
  computed: {
    submitbutton() {
      return !(
        this.CategoryData.category_name.length > 0 &&
        this.CategoryData.category_img.length > 0
      );
    },
  },
  methods: {
    uuidv4,
    saveCategoryVideo(categoryVideo) {
      this.CategoryData.video = categoryVideo;

    },

    getFileExtensionFromUrl(url) {
      if (!url) return '';
      const path = url.split('?')[0].split('#')[0];
      const extension = path.split('.').pop();

      if (extension === path) return '';

      return extension;
    },


    showVideosModel() {
      this.statusVideosModel = true;
    },
    modelVideoClose() {
      this.statusVideosModel = false;
    },
    saveCategoryImage(categoryImage) {
      this.CategoryData.category_img = categoryImage;
      this.keyForLottie += 1;
    },
    modelClose(modelState) {
      this.showImage = modelState;
    },
    getImages() {
      if (this.searchQuery.trim().length > 0) {
        return this.table_data.filter((item) => {
          if (item.image_name) {
            return this.searchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.image_name.toLowerCase().includes(v));
          }
        });
      } else {
        return this.table_data;
      }
    },
    gettabledata: function () {
      axios
        .get(process.env.VUE_APP_API_URL + "/zape-cms/gallery", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          // console.log(response.data);
          this.table_data = get_response.data;
          this.keyForLottie = this.keyForLottie + 1;
        });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.CategoryData = {
        category_name: this.Editcategoryname,
        category_img: this.EditCategoryImage,
        order: this.editOrder,
      };
      this.searchQuery = "";
      this.showImage = false;
      this.removebutton = true;
      this.validated = false;
    },
    onSubmit() {
      this.CategoryData.img_name = this.orginal_name["name"];
      this.CategoryData.id = this.Editclassdata;
      this.CategoryData.subject_id = this.Editsubjectid;
      this.CategoryData.category_id = this.Editcategoryid;
      this.edit_category_data = this.CategoryData;
      this.$emit("clickEditCategorydata", this.edit_category_data);
      this.CategoryData.category_name = null;
    },
    showgalleryimage() {
      this.showImage = true;
      this.removebutton = false;
    },
    hideimagegallery() {
      this.showImage = false;
      this.removebutton = true;
    },
    galleryimg(data) {
      this.CategoryData.category_img = data;
      this.CategoryData.value = "1";
    },
    getFileExtension(mimeType) {
            const mimeToExtension = {
                'image/jpeg': 'jpg',
                'image/png': 'png',
                'image/gif': 'gif',
                'image/svg+xml': 'svg',
                '.svg': 'svg',
                'application/json': 'json',
                '.json': 'json',
                '.lottie': 'lottie',
                'lottie': 'lottie',
            };
            return mimeToExtension[mimeType] || 'png';
        },
        async uploadFile(file, url, key, ext) {
            try {
                const response = await axios.put(url, file, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': file.type,
                    },
                });

                if (response.status !== 200) {
                    console.error('Error uploading file:', response);
                }
                let s3Url = 'https://zape02.s3.ap-southeast-1.amazonaws.com/' + key;
                this.CategoryData.category_img= s3Url;
                this.CategoryData.value = 0;
                  this.keyForLottie += 1;
                // this.localKeyForLottie += 1;

            } catch (error) {
                this.isLoading = false;
                console.error('Error uploading file:', error);
            }
        },
    async inputImageRenderer(input) {
      if (input.target.files && input.target.files[0]) {
          const file = event.target.files[0];
            if (file) {
                this.isLoading = true;
                try {
                    const fileType = file.name.includes('.lottie') ? 'lottie'  : file.type;
                    const fileExtension = this.getFileExtension(fileType);
                    const response = await axios.post(process.env.VUE_APP_API_URL + '/s3/generate-presigned-url', {
                        type: file.type,
                        ext: fileExtension
                    }, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("_t"),
                        },
                    });
                    const { url, key } = response.data;
                    const extention = fileExtension == 'json' ? 'lottie' : fileExtension
                    await this.uploadFile(file, url, key, extention);
                } catch (error) {
                    this.isLoading = false;
                    console.error('Error getting pre-signed URL:', error);
                }
            }

        }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
